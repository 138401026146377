@tailwind base;
@tailwind components;
@tailwind utilities;


.App {
  font-family: "Menlo", "Roboto Mono", "Courier New", Courier, monospace;
  text-align: center;
  overflow: hidden;
}
.text-glow-blue {
  text-shadow: 0 0 2px rgba(99, 102, 241, 0.5), 0 0 4px rgba(99, 102, 241, 0.5), 0 0 6px rgba(99, 102, 241, 0.5), 0 0 8px rgba(99, 102, 241, 0.5), 0 0 10px rgba(99, 102, 241, 0.5), 0 0 12px rgba(99, 102, 241, 0.5);
}
.text-glow-green {
  text-shadow: 0 0 1px rgba(0, 255, 0, 0.3), 0 0 2px rgba(0, 255, 0, 0.3), 0 0 3px rgba(0, 255, 0, 0.3), 0 0 4px rgba(0, 255, 0, 0.3), 0 0 5px rgba(0, 255, 0, 0.3), 0 0 6px rgba(0, 255, 0, 0.3);
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.gradient-scrollbar::-webkit-scrollbar-track {
  background: transparent; /* Background of the track */
}

.gradient-scrollbar::-webkit-scrollbar-thumb {
  background: linear-gradient(to right, rgba(75, 85, 99, 0.7), rgba(99, 102, 241, 0.7), rgba(139, 92, 246, 0.7)); /* Gradient color with opacity */
  border-radius: 8px; /* Round corners */
}

.gradient-scrollbar {
  scrollbar-color: rgba(75, 85, 99, 0.7) transparent; /* For non-webkit browsers with opacity */
  scrollbar-width: thin;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

